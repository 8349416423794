<template>
  <span />
</template>

<script>
export default {
  name: 'CjTracker',
  props: {
    routeSlug: {
      type: String,
      default: '',
    },
    pageType: {
      type: String,
      default: '',
    },
    routeCj: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const staticPages = [
      'faq',
      'custom-jackets',
      'school-jackets',
      'club-jackets',
      'returns-and-exchanges',
      'terms-and-conditions',
      'privacy-policy',
      'our-story',
      'materials',
    ]

    let trackingData = {
      enterpriseId: 1564957,
    }

    if (this.pageType) {
      trackingData.pageType = this.pageType
    }

    if (staticPages.includes(this.routeSlug)) {
      trackingData.pageType = 'category'
    }

    this.cj.sitePage = trackingData

    if (this.routeCj) {
      this.cjInit(this.routeCj)
    }
  },
}
</script>
