<template>
  <aside id="offCanvasCart" data-uk-offcanvas="overlay: true; flip: true">
    <div class="uk-offcanvas-bar uk-padding">
      <button class="uk-offcanvas-close uk-icon-button" data-uk-close aria-label="close" type="button" />

      <div class="uk-margin-bottom">
        <strong class="uk-h3 uk-display-block uk-text-bold uk-margin-remove" style="margin-bottom: 6px !important">
          Your bag
          <sup class="vb-font-family-global uk-text-normal uk-text-small">({{ itemCount }})</sup>
        </strong>

        <p v-if="itemCount" class="uk-text-small uk-margin-small-top">
          <a class="uk-flex-inline uk-flex-middle" style="border: none" :href="routeCart">
            <span style="margin-right: 6px" data-uk-icon="bag" />
            <span>Edit bag</span>
          </a>
        </p>
      </div>
      <form>
        <template v-if="itemCount">
          <ul class="uk-list uk-margin-bottom" style="border-top: 1px solid #e5e7e8">
            <li
              v-for="(item, itemIdx) in items"
              :key="itemIdx"
              class="uk-padding-small uk-padding-remove-horizontal"
              style="border-bottom: 1px solid #e5e7e8"
            >
              <div class="uk-padding-small uk-padding-remove-horizontal">
                <div class="uk-grid-medium" data-uk-grid>
                  <div class="uk-width-auto">
                    <div class="uk-position-relative">
                      <img
                        class="vb-img-thumb uk-border-rounded"
                        style="max-width: 120px"
                        data-uk-img
                        :data-src="item.imageCart"
                        :alt="item.title"
                      />
                      <button
                        class="uk-icon-button uk-button-default uk-border-circle uk-padding-remove uk-width-auto uk-position-absolute"
                        style="height: 32px; width: 32px; top: -12px; left: -12px; background-color: white !important"
                        data-uk-tooltip="Remove"
                        title="Remove"
                        data-uk-icon="icon: trash; ratio: .5"
                        type="button"
                        @click="removeItem(item.key)"
                      />
                    </div>
                  </div>
                  <div class="uk-width-expand">
                    <a class="uk-text-small uk-display-block uk-margin-small-bottom" href="#">
                      <strong>{{ item.title }}</strong>
                    </a>
                    <p class="uk-margin-remove uk-text-small">{{ item.quantity }} x {{ item.size.name }}</p>
                    <div>
                      <strong v-if="!item.isOnSale">
                        {{ getFormattedPrice(item.prices.retail, item.size.price_modifier) }}
                      </strong>
                      <template v-else>
                        <strong>{{ getFormattedPrice(item.prices.sale, item.size.price_modifier) }}</strong>
                        <del style="font-size: 0.75rem" class="uk-text-small">
                          {{ getFormattedPrice(item.prices.retail, item.size.price_modifier) }}
                        </del>
                      </template>
                    </div>
                  </div>
                </div>
                <ul v-if="item.isAddonExtraPocketEnabled" class="uk-list uk-margin-top uk-margin-remove-bottom">
                  <li style="margin-top: 6px">
                    <label class="uk-flex uk-flex-middle">
                      <input
                        class="uk-checkbox uk-margin-remove"
                        type="checkbox"
                        :checked="item.addonExtraInnerPocket"
                        @input="onChange(item, 'addonExtraInnerPocket', $event)"
                      />
                      <span class="uk-margin-small-left uk-text-small">
                        Add extra inside
                        <a
                          data-uk-toggle
                          href="#infoExtraPocket"
                          class="uk-text-bold"
                          style="border-bottom: 1px dotted"
                        >
                          pocket
                        </a>
                        (+ {{ getFormattedPrice(addonPrices.addonExtraInnerPocket) }})
                      </span>
                    </label>
                  </li>
                  <li v-if="item.isAddonZipLiningEnabled" style="margin-top: 6px">
                    <label class="uk-flex uk-flex-middle">
                      <input
                        class="uk-checkbox uk-margin-remove"
                        type="checkbox"
                        :checked="item.addonDetachableZipLining"
                        @input="onChange(item, 'addonDetachableZipLining', $event)"
                      />
                      <span class="uk-margin-small-left uk-text-small">
                        Add
                        <a
                          data-uk-toggle
                          href="#infoZipOutLining"
                          class="uk-text-bold"
                          style="border-bottom: 1px dotted"
                        >
                          Zip-out lining
                        </a>
                        (+ {{ getFormattedPrice(addonPrices.addonDetachableZipLining) }})
                      </span>
                    </label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div class="uk-grid-collapse" data-uk-grid>
            <div class="uk-width-auto">
              <a
                href="#"
                class="uk-button uk-button-primary uk-text-bold vb-btn-hover-scale"
                style="border-radius: 48px"
                :onclick="handleCheckout"
              >
                <span style="margin-left: -6px; width: 24px">
                  <img src="@/web/img/misc/secure-payment.svg" data-uk-svg alt="Secure checkout icon" />
                </span>
                <span class="uk-margin-remove">Checkout</span>
              </a>
            </div>
            <div class="uk-width-expand uk-flex-middle uk-text-right">
              <span class="uk-margin-remove uk-text-small uk-display-block">Subtotal</span>
              <strong class="uk-h4 uk-margin-remove uk-text-bold">
                {{ getFormattedPrice(orderPrices.salePrice) }}
              </strong>
            </div>
          </div>

          <form ref="checkoutForm" action="/checkout/stripe" method="POST" style="visibility: hidden">
            <input type="hidden" name="_token" :value="csrf" />
            <button id="checkout-button" type="submit">Checkout</button>
          </form>

          <p class="uk-margin-top uk-text-center uk-text-small uk-padding-small uk-background-muted">
            We accept all major credit cards, as well as payments via PayPal.
            <strong class="uk-text-primary uk-text-nowrap">FREE DELIVERY</strong>
            Worldwide!
          </p>
        </template>
        <p v-else>Your shopping cart is empty...</p>

        <template v-if="loading">
          <div class="uk-overlay-default uk-position-cover" />
          <div class="uk-position-center" data-uk-spinner />
        </template>

        <InfoExtraPocketModal :price="getFormattedPrice(addonPrices.addonExtraInnerPocket)" />
        <InfoZipOutliningModal />
      </form>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { getFormattedPrice, getItemKey } from '@/web/js/helpers'
import { debounceLodash } from '@/shared/js/lodash-helpers'

import InfoExtraPocketModal from './InfoExtraPocketModal.vue'
import InfoZipOutliningModal from './infoZipOutLiningModal.vue'

export default {
  name: 'SidebarCart',
  components: { InfoZipOutliningModal, InfoExtraPocketModal },
  props: {
    wizardRoute: {
      type: String,
      default: '',
    },
    routeGetData: {
      type: String,
      default: '',
    },
    routeCart: {
      type: String,
      default: '',
    },
    routeCheckout: {
      type: String,
      default: '',
    },
    routeUpdate: {
      type: String,
      default: '',
    },
    routeCj: {
      type: String,
      default: '',
    },
    personalizationPrices: {
      type: Object,
      default: () => {},
    },
    csrf: {
      type: String,
      default: '',
      require: true,
    },
  },
  computed: {
    ...mapGetters('basket', ['items', 'loading', 'orderPrices', 'addonPrices']),
    itemCount() {
      return this.items.length
    },
  },
  created() {
    this.setRoute(this.routeUpdate)
    this.setPrices(this.personalizationPrices)
    this.setCjRoute(this.routeCj)
    this.fetchItems({ getRoute: this.routeGetData })
  },
  methods: {
    handleCheckout() {
      this.setCheckoutGaData()
      this.$refs.checkoutForm.submit()
    },
    getFormattedPrice,
    ...mapActions('basket', [
      'changeItemQuantity',
      'decreaseItemQuantity',
      'increaseItemQuantity',
      'removeItem',
      'setItemSize',
      'setRoute',
      'setCjRoute',
      'fetchItems',
      'setPrices',
      'setItemAddon',
      'setCheckoutGaData',
    ]),
    ...mapMutations('basket', ['setCurrentProduct']),
    onInput: debounceLodash(function (itemKey, e) {
      const val = Number(e?.target?.value)

      if (val) {
        this.changeItemQuantity([itemKey, val])
      }
    }, 500),
    onBlur(itemKey, e) {
      const val = Number(e?.target?.value)

      if (!val) {
        this.changeItemQuantity([itemKey, 1])
      }
    },
    getRouteCustomize(item) {
      return item && item.slug ? `/customize/${item.slug}?basket-item-key=${getItemKey(item, true)}` : '#'
    },
    onChange(item, addonName, e) {
      const value = e?.target?.checked || false

      this.setItemAddon({ item, addonName, value })
    },
    copyProduct(item) {
      this.setCurrentProduct({
        ...item,
        quantity: 1,
        cloned_product_instance_id: item.product_instance_id,
        product_instance_id: null,
      })
    },
  },
}
</script>
