<template>
  <div>
    <products-slider :customers="customers" :logos="logos" />

    <aside class="uk-container uk-container-large">
      <template v-if="loading">
        <!--LOADING OVERLAY-->
        <div class="uk-overlay-default vb-overlay-cart uk-position-cover" />
        <div class="uk-position-center vb-spinner-cart-update" data-uk-spinner />
        <!--end of LOADING OVERLAY-->
      </template>

      <div class="uk-background-muted uk-padding vb-rounded" :style="{ backgroundImage: `url(${vectorPatternLight})` }">
        <div
          class="uk-child-width-2-3@s uk-child-width-1-2@l uk-child-width-1-3@xl uk-flex-center uk-text-center"
          data-uk-grid
        >
          <div>
            <h2 class="uk-h2 uk-text-bold uk-margin-small-bottom">Subscribe For Updates</h2>
            <p class="uk-margin-remove-top uk-text-bold uk-width-3-5 uk-margin-auto-left uk-margin-auto-right">
              Sign up for great deals, discounts and new product launch specials!
            </p>
            <div class="uk-width-auto">
              <div class="uk-position-relative">
                <input
                  v-model="email"
                  :class="inputClasses"
                  style="border-radius: 60px; text-indent: 12px; height: 54px"
                  type="email"
                  placeholder="Enter Your Email"
                  required
                />
                <button
                  id="sunscribeButton"
                  class="uk-button uk-button-secondary uk-position-absolute uk-text-bold"
                  style="right: 3px; top: 3px; border-radius: 48px"
                  :disabled="!email"
                  @click="submitData()"
                >
                  <span style="margin-left: -6px; width: 20px">
                    <img src="@/web/img/uploads/subscribe.svg" data-uk-svg alt="Personalization icon" />
                  </span>
                  <span class="uk-visible@s uk-margin-remove">Subscribe</span>
                </button>
                <p
                  v-if="status === 'error'"
                  class="uk-text-small uk-text-bold uk-text-danger uk-margin-small-top uk-padding-small uk-margin-small-left uk-padding-remove-vertical uk-margin-remove-bottom"
                >
                  Something went wrong.
                </p>
                <p
                  v-if="status === 'success'"
                  class="uk-text-small uk-text-success uk-text-left uk-margin-small-top uk-padding-small uk-margin-small-left uk-padding-remove-vertical"
                >
                  Thank you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import vectorPatternLight from '@/web/img/brand/vector-pattern-light.png'
import { getImgPath } from '@/shared/js/helpers'
import ProductsSlider from '@/web/vue/shared/components/ProductsSlider.vue'

export default {
  name: 'SubscribeForm',
  components: { ProductsSlider },
  props: {
    route: {
      type: String,
      default: '',
    },
    slugName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vectorPatternLight,
      email: '',
      loading: false,
      status: null,
      logos: {
        1: {
          src: getImgPath('/img/uploads/logos/anheuser.png'),
          alt: 'Anheuser Busch logo',
        },
        3: {
          src: getImgPath('/img/uploads/logos/carolina.png'),
          alt: 'Carolina Panthers NFL team logo',
        },
        5: {
          src: getImgPath('/img/uploads/logos/dominos.png'),
          alt: 'Domino’s Pizza logo',
        },
        7: {
          src: getImgPath('/img/uploads/logos/harvard.png'),
          alt: 'Harward University logo',
        },
        9: {
          src: getImgPath('/img/uploads/logos/kw.png'),
          alt: 'Keller Williams logo',
        },
        11: {
          src: getImgPath('/img/uploads/logos/teton-ridge.png'),
          alt: 'Teton Ridge logo',
        },
        13: {
          src: getImgPath('/img/uploads/logos/tiktok.png'),
          alt: 'TikTok logo',
        },
        15: {
          src: getImgPath('/img/uploads/logos/tripledot.png'),
          alt: 'Tripledot logo',
        },
        17: {
          src: getImgPath('/img/uploads/logos/youtube.png'),
          alt: 'YouTube logo',
        },
        19: {
          src: getImgPath('/img/uploads/logos/paramount.png'),
          alt: 'Paramount logo',
        },
      },
      customers: {
        'customer-1': getImgPath('/img/uploads/customers/customer-1.jpg'),
        'customer-2': getImgPath('/img/uploads/customers/customer-2.jpg'),
        'customer-3': getImgPath('/img/uploads/customers/customer-3.jpg'),
        'customer-4': getImgPath('/img/uploads/customers/customer-4.jpg'),
        'customer-5': getImgPath('/img/uploads/customers/customer-5.jpg'),
        'customer-6': getImgPath('/img/uploads/customers/customer-6.jpg'),
        'customer-7': getImgPath('/img/uploads/customers/customer-7.jpg'),
        'customer-8': getImgPath('/img/uploads/customers/customer-8.jpg'),
        'customer-9': getImgPath('/img/uploads/customers/customer-9.jpg'),
        'customer-10': getImgPath('/img/uploads/customers/customer-10.jpg'),
        'customer-11': getImgPath('/img/uploads/customers/customer-11.jpg'),
        'customer-12': getImgPath('/img/uploads/customers/customer-12.jpg'),
        'customer-13': getImgPath('/img/uploads/customers/customer-13.jpg'),
        'customer-14': getImgPath('/img/uploads/customers/customer-14.jpg'),
        'customer-15': getImgPath('/img/uploads/customers/customer-15.jpg'),
        'customer-16': getImgPath('/img/uploads/customers/customer-16.jpg'),
        'customer-17': getImgPath('/img/uploads/customers/customer-17.jpg'),
        'customer-18': getImgPath('/img/uploads/customers/customer-18.jpg'),
        'customer-19': getImgPath('/img/uploads/customers/customer-19.jpg'),
        'customer-20': getImgPath('/img/uploads/customers/customer-20.jpg'),
      },
    }
  },
  computed: {
    inputClasses() {
      return {
        'uk-input uk-form-large uk-text-small uk-background-default': true,
        'uk-form-danger': this.status === 'error',
      }
    },
  },
  methods: {
    submitData() {
      let that = this

      if (that.email !== null) {
        that.loading = true
        that.status = null
        this.axios
          .post(that.route, { email: that.email })
          .then(response => {
            that.loading = false

            if (response.data.status === 'success') {
              that.email = null
              that.status = 'success'
              if (this.$gtm.enabled()) {
                window.dataLayer.push({
                  event: 'newsletterFormSubmit',
                })
              }
            } else {
              that.status = 'error'
            }
          })
          .catch(() => {
            that.loading = false
            that.status = 'error'
          })
      }
    },
  },
}
</script>
